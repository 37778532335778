import React from 'react'

const Chip = (props) => {

  const { 
    type = 'default',
    text = 'text',
  } = props;

  const generateBGColor = () => {
    switch (type) {
      case 'danger':
        return 'bg-red-200';
      case 'success':
        return 'bg-green-200';
      case 'warning':
        return 'bg-yellow-200';
      default:
        return 'bg-gray-200'
    }
  }

  const generateTextColor = () => {
    switch (type) {
      case 'danger':
        return 'text-red-900';
      case 'success':
        return 'text-green-900';
      case 'warning':
        return 'text-yellow-900';
      default:
        return 'text-gray-900'
    }
  }


  return (
    <span className="relative inline-block px-3 py-1 font-semibold leading-tight">
      <span aria-hidden className={`absolute inset-0 ${generateBGColor()} opacity-50 rounded-full`}></span>
      <span className={`relative text-xs ${generateTextColor()} uppercase`}>{text}</span>
    </span>
  )
}

export default Chip;

