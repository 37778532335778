import React from 'react'


const Button = props => {

  const { 
    size = 'normal',
    variant = 'default',
    color = 'yellow',
    fullWidth = false,
  } = props;

  // size
  const sizes = {
    normal: 'px-5 py-2 ',
    small: 'px-3 py-1 text-sm',
  }

  /** 
   * 
   *  As much as I hate it CSS Purge,a plugin that Tailwind uses 
   *  needs full name of classes to be used to be able to know
   *  which classes should be generated
   * 
  */
 
  const generateBorderColor = () => {
    switch (color) {
      case 'blue':
        return 'border-blue-500';
      case 'red':
        return 'border-red-500';
      case 'green':
        return 'border-green-500';
      case 'yellow':
        return 'border-yellow-500';
      default:
        return 'border-gray-500'
    }
  }

  const generateBGColor = () => {
    switch (color) {
      case 'blue':
        return 'bg-blue-500';
      case 'red':
        return 'bg-red-500';
      case 'green':
        return 'bg-green-500';
      case 'yellow':
        return 'bg-yellow-500';
      default:
        return 'bg-gray-500'
    }
  }

  const generateHoverBGColor = () => {
    switch (color) {
      case 'blue':
        return 'hover:bg-blue-500';
      case 'red':
        return 'hover:bg-red-500';
      case 'green':
        return 'hover:bg-green-500';
      case 'yellow':
        return 'hover:bg-yellow-500';
      default:
        return 'hover:bg-gray-500'
    }
  }

  const generateTextColor = () => {
    switch (color) {
      case 'blue':
        return 'text-blue-500';
      case 'red':
        return 'text-red-500';
      case 'green':
        return 'text-green-500';
      case 'yellow':
        return 'text-yellow-500';
      default:
        return 'text-gray-500'
    }
  }

  // variant
  const variants = {
    default: `${generateBorderColor()} ${generateBGColor()} border text-white rounded transition duration-300 ${generateHoverBGColor} hover:text-white focus:outline-none`,
    outline: `${generateBorderColor()} border ${generateTextColor()} rounded transition duration-300 ${generateHoverBGColor} hover:text-black focus:outline-none bg-white`,
  }
  
  const generateClassname =() =>{
    return `button ${variants[variant]} ${sizes[size]} ${fullWidth ? 'w-full' : ''} disabled:opacity-50`;
  }
  return (
    <>
    <button 
      className={generateClassname()}
      {...props}
    >
    </button>
  
    </>
  )
}

export default Button;