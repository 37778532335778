import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../components/layout';
import dayjs from 'dayjs';
// firebase
import { useListVals, useObject, useObjectVal } from 'react-firebase-hooks/database';
import Input from '../components/input';
import Button from '../components/button';
import Table from '../components/table';
import Modal from '../components/modal';
import AddOnsTable from './settings/addOnsTable';
import VehicleTable from './settings/vehicleTable';
import CardsTable from './settings/cardsTable';
import { child, push, ref, remove, update } from 'firebase/database';
import { rtdb } from '../helpers/firebase';
import CancelTable from './settings/cancelTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Settings = () => {
  const [state, setState] = useState({});

  const [settings, settingsLoading, settingsError] = useObjectVal(
    ref(rtdb, 'settings')
  );

  useEffect(() => {
    if (!settingsLoading) {
      setState(settings);
    }
  }, [settings]);


  const handleUpdate = (id, settingsField, editData, callback) => {
    const _data = editData;
    delete _data.loading;

    const fieldRef = ref(rtdb, `settings/${settingsField}/${id}`)

    update(fieldRef, { ..._data }).then(err => {
      if (err) {
        toast.error('Operation failed');
      }
      else {
        toast.success('Item successfully updated!');
        callback();
      }
    });
  }

  const handleCreate = (settingsField, editData, callback) => {
    const _data = editData;
    delete _data.loading;
    delete _data.new;

    const newKey = push(child(ref(rtdb), `settings/${settingsField}`)).key;

    const updates = {};
    updates[`settings/${settingsField}/` + newKey] = _data;



    update(ref(rtdb), updates).then(() => {
      toast.success('Successfully added!');
      callback();
    }).catch(err => console.log(err));
  }

  const handleDelete = useCallback((id, settingsField) => {

    const fieldRef = ref(rtdb, `settings/${settingsField}/${id}`)
    remove(fieldRef).then(err => {
      if (err) {
        toast.error('Operation Failed');
      }
      else {
        toast.success('Item successfully deleted!');
      }
    });
  }, [settings]);



  return (
    <div className='font-admin'>
      <h1 className="text-2xl font-bold mb-2">
        Settings
      </h1>
      <ToastContainer />
      <h1 className="text-xl font-bold mb-2 capitalize">
        App Versions
      </h1>
      <div className='flex gap-2 mb-8'>
        <div className="form-control">
          <label className="input-group">
            <span className='bg-primary font-bold'>Pinbuggy App</span>
            <input type="text" value={settings?.app_versions?.pinbuggy_app} className="input input-bordered" />
          </label>
        </div>
        <div className="form-control">
          <label className="input-group">
            <span className='bg-secondary text-white font-bold'>Pinbuggy Driver</span>
            <input type="text" value={settings?.app_versions?.pinbuggy_driver} className="input input-bordered" />
          </label>
        </div>
      </div>
      <AddOnsTable
        data={settings}
        loading={settingsLoading}
        handleUpdate={handleUpdate}
        handleCreate={handleCreate}
        handleDelete={handleDelete}
      />
      <VehicleTable data={settings} loading={settingsLoading} />
      {/* DRIVER CANCELLATION REASONS */}
      {
        settings && (
          <CancelTable
            data={settings?.cancellation_reasons ? settings?.cancellation_reasons.driver : []}
            loading={settingsLoading}
          />
        )
      }

      {/* USER CANCELLATION REASONS */}
      {
        settings && (
          <CancelTable
            data={settings?.cancellation_reasons ? settings?.cancellation_reasons.user : []}
            loading={settingsLoading}
            settingsField='cancellation_reasons/user'
            title='Cancellation reasons for Users'
          />
        )
      }

      {/* REPORT DRIVER REASONS */}
      {
        settings && (
          <CancelTable
            data={settings?.report_reasons ? settings?.report_reasons.user : []}
            loading={settingsLoading}
            settingsField='report_reasons/user'
            title='User reasons to report driver'
          />
        )
      }


      {/* <CardsTable data={settings} loading={settingsLoading} /> */}

    </div>
  )
}

export default Settings;





