import React from "react"
import { Router, Redirect } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Login from "../admin/login"
import Users from "../admin/users"
import Drivers from "../admin/drivers"
import DriverTracker from "../admin/driverTrack"
import Settings from "../admin/settings"
import { Helmet } from "react-helmet"
import Cancellations from "../admin/cancellations"
import ReportedDrivers from "../admin/reported"
import Feedback from "../admin/feedback"
// import components here

const App = () => {



  return (
    <div>

      <Helmet>
        <title>Pinbuggy Admin</title>
      </Helmet>
      <Router>
        <Login path="/admin" />
        <PrivateRoute path="/admin/users" component={Users} />
        <PrivateRoute path="/admin/drivers" component={Drivers} />
        <PrivateRoute path="/admin/driver-tracker" component={DriverTracker} />
        <PrivateRoute path="/admin/settings" component={Settings} />
        <PrivateRoute path="/admin/cancellations" component={Cancellations} />
        <PrivateRoute path="/admin/reported-drivers" component={ReportedDrivers} />
        <PrivateRoute path="/admin/feedbacks" component={Feedback} />
        {/* <Login path="/app/login" /> */}
      </Router>
    </div>
  )
}

export default App