import React, { useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import Button from './button';
import Loading from './loading';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

const Lightbox = (props) => {
  const { 
    children,
     handleClose,
     visible,
     images = []
    } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [scale, setScale] = useState(1);

  
  const handleZoom = (zoomIn) => {
    const zoomFactor = 0.5;
    const min = 0.5;
    const max = 2;

    // if (scale <= min || scale >= max)return;

    if (zoomIn && scale < max) {
      setScale(scale + zoomFactor)
      return;
    }

    else {
      if (scale <= min) return;
      setScale(scale - zoomFactor)
    }





    
  }
  if (!visible) return null;
  return (
    // container
    <div className="fixed flex items-center justify-center top-0 left-0 w-full h-full transition-opacity z-30 bg-black bg-opacity-75">

<button className="absolute top-2 right-2 p-2 block bg-white rounded-full shadow-2xl" onClick={handleClose}>
          <FaTimes/>
        </button>

        <Loading className="absolute w-16 h-16 " color="white" style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}/>
      <PinchZoomPan position="center" maxScale="3">

        <img src={images[activeIndex]}/>
      </PinchZoomPan>


        <div className="absolute bottom-0">
        {/* <Button>prev</Button>
        <Button>next</Button>
        <Button onClick={() => handleZoom(true)}>zoom +</Button>
        <Button onClick={() => handleZoom(false)}>zoom -</Button> */}
        </div>


       
          {children}
    </div>
  )
}

export default Lightbox;

