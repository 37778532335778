import React, { useEffect, useState } from 'react';

// components
import Toolbar from '../components/toolbar';
import Table from '../components/table';
import reasonsPaginatedFetch from '../helpers/reasonsPaginatedFetch';
import dayjs from 'dayjs';
import Modal from '../components/modal';
import Input from '../components/input';
import Label from '../components/label';
import { FaCheck, FaCheckCircle, FaStop, FaStopCircle, FaTimes } from 'react-icons/fa';
import { ExternalLink } from 'react-feather';
import { Link } from 'gatsby';
import Chip from '../components/chips';
import { arrayUnion, deleteField, doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { db } from '../helpers/firebase';
import { toast, ToastContainer } from 'react-toastify';


const Cancellations = (params) => {
  const [showModal, setShowModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [image, setImage] = useState(null);
  const [data, setData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(dayjs());
  const [updateCounter, setUpdateCounter] = useState(0);
  const [lastKey, setLastKey] = useState('');
  const [nextDataLoading, setNextDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [editData, setEditData] = useState({ loading: true });
  const [message, setMessage] = useState('');


  const getInitialData = () => {
    reasonsPaginatedFetch
      .dataFirstBatch('cancellation_requests', searchValue)
      .then((res) => {
        setData(res.data);
        setLastKey(res.lastKey);
        setLastUpdated(dayjs());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInitialData();
  }, [searchValue]);


  useEffect(() => {
    const timer = setInterval(() => setUpdateCounter(updateCounter + 1), 5000);
    return () => clearInterval(timer);
  }, [updateCounter]);

  const fetchMoreData = () => {
    if (lastKey.hasOwnProperty('seconds') || lastKey.length > 0) {
      setNextDataLoading(true);
      reasonsPaginatedFetch
        .dataNextBatch('cancellation_requests', lastKey, searchValue)
        .then((res) => {
          setLastKey(res.lastKey);
          setData(data.concat(res.data));
          setNextDataLoading(false);
          setLastUpdated(dayjs());
        })
        .catch((err) => {
          console.log(err);
          setNextDataLoading(false);
        });
    }
  };


  const handleSearch = (value) => {
    setSearchValue(value);
  }


  const tableHeaders = [
    { name: 'Title' },
    { name: 'Date' },
    { name: 'Requested by' },
    { name: 'Status' },
    { name: 'Action' },
  ];


  const status = (cancellation_status) => {
    if (cancellation_status == 'pending') {
      return {
        text: 'pending',
        type: 'warning',
        showAsChip: true,
      }
    }
    else if (cancellation_status == 'accepted') {
      return {
        text: 'accepted',
        type: 'success',
        showAsChip: true,
      }
    }
    else if (cancellation_status == 'rejected') {
      return {
        text: 'rejected',
        type: 'danger',
        showAsChip: true,
      }
    }
    else if (cancellation_status == 'trip cancelled') {
      return {
        text: 'trip cancelled',
        type: 'danger',
        showAsChip: true,
      }
    }
    // else {
    //   return {
    //     text: 'unverified',
    //     type: 'danger',
    //     showAsChip: true,

    //   }
    // }
  }

  // table data
  const tableData = data && data.map((_data, i) => {
    const { date_created, cancellation_status, reporter_data, report } = _data;


    return {
      index: i,
      cancel_title: report.title,
      date_created: dayjs(date_created.toDate()).format('MMM DD, YYYY h:mm a'),
      requested_by: reporter_data.full_name,
      status: status(cancellation_status)
    }
  })



  const handleCloseModal = () => {
    setShowModal(false);
    setEditData({ loading: true })
  }

  const handleModalOpen = async (index) => {

    // console.log('data[index]', data[index])
    setShowModal(true);
    setEditData({
      ...data[index],
      // index,
      loading: false
    });

  }

  // useEffect(() => {
  //   console.log(data);
  // }, [data])

  const actions = [
    {
      name: 'View',
      action: handleModalOpen
    },
  ]

  const handleAccept = async () => {
    const cancelData = {
      cancellation_accepted_at: serverTimestamp(),
      cancellation_status: 'accepted'
    }

    const tripData = {
      accepted_at: null,
      accepted_by: deleteField(),
      driver_info: deleteField(),
      admin_cancellation: {
        status: 'resolved',
        date: serverTimestamp()
      },
      status: 'active',
      user_cancellation_rejected: deleteField(),
      admin_cancellation_history: arrayUnion({
        details: { ...editData.reporter_data, ...editData.report, accepted: true },
      })
    };

    const TRIP_COLLECTION = editData?.test ? 'mock_trips' : 'trips';

    try {
      const batch = writeBatch(db);
      const tripRef = doc(db, TRIP_COLLECTION, editData?.trip_id);
      const cancelRef = doc(db, "cancellation_requests", editData?.id);
      batch.update(tripRef, tripData);
      batch.update(cancelRef, cancelData);
      await batch.commit();
      toast.success('Report now marked as resolved!');
      getInitialData();

    } catch (error) {
      toast.error('Operation failed');
      console.log(error);
    }
    finally {
      // setMessage('');
      handleCloseModal();
    }
  }

  const handleReject = async () => {
    // add message to driver
    const cancelData = {
      cancellation_rejected_at: serverTimestamp(),
      cancellation_status: 'rejected'
    }

    const tripData = {

      admin_cancellation: {
        status: 'rejected',
        date: serverTimestamp()
      },
      user_cancellation_rejected: deleteField(),
      admin_cancellation_history: arrayUnion({
        details: { ...editData.reporter_data, ...editData.report, rejected: true },
      })
    };

    const TRIP_COLLECTION = editData?.test ? 'mock_trips' : 'trips';

    try {
      const batch = writeBatch(db);
      const tripRef = doc(db, TRIP_COLLECTION, editData?.trip_id);
      const cancelRef = doc(db, "cancellation_requests", editData?.id);
      batch.update(tripRef, tripData);
      batch.update(cancelRef, cancelData);
      await batch.commit();
      toast.success('Report marked as rejected!');
      getInitialData();

      //reload the page
    } catch (error) {
      toast.error('Operation failed');
      console.log(error);
    }
    finally {
      // setMessage('');
      handleCloseModal();
      // window.location.reload();
    }
  }

  const handleCancel = async () => {
    // cancelled
    if (!message) return;
    const cancelData = {
      trip_cancelled_at: serverTimestamp(),
      cancellation_status: 'trip cancelled'
    }

    const tripData = {
      admin_cancellation: {
        status: 'cancelled',
        date: serverTimestamp()
      },
      status: 'cancelled',
      cancelled_at: serverTimestamp(),
      cancellation_details: {
        reason_note: message,
        cancelled_by_admin: true,
      },
      user_cancellation_rejected: deleteField(),
      admin_cancellation_history: arrayUnion({
        details: { ...editData.reporter_data, ...editData.report, cancelled: true },
      })
    };
    const TRIP_COLLECTION = editData?.test ? 'mock_trips' : 'trips';

    try {
      const batch = writeBatch(db);
      const tripRef = doc(db, TRIP_COLLECTION, editData?.trip_id);
      const cancelRef = doc(db, "cancellation_requests", editData?.id);
      batch.update(tripRef, tripData);
      batch.update(cancelRef, cancelData);
      await batch.commit();
      toast.success('Report marked as rejected!');
      getInitialData();
      //reload the page
    } catch (error) {
      toast.error('Operation failed');
      console.log(error);
    }
    finally {
      // setMessage('');
      handleCloseModal();
      // window.location.reload();
    }
  }

  const isAccepted = editData.cancellation_status == 'accepted';
  const isRejected = editData.cancellation_status == 'rejected';
  const isTripCancelled = editData.cancellation_status == 'trip cancelled';
  const notPending = editData.cancellation_status != 'pending'

  return (
    <div className='font-admin'>
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-2">
        Cancellation Requests
      </h1>

      {/* <Toolbar actionTitle="Add" action={() => setModalVisible(!isModalVisible)} searchHandler={handleSearch} searchPlaceholder="Type a user name and press enter..." /> */}
      <Table headers={tableHeaders} data={tableData} rowActions={actions} />
      {
        lastKey != '' || lastKey.hasOwnProperty('seconds') ? (
          <button className="btn btn-secondary mt-5" disabled={nextDataLoading} onClick={() => fetchMoreData()}>
            {
              nextDataLoading ? 'Loading...' : 'Load more'
            }
          </button>
        ) : (
          <div className='mt-2 text-gray-300'>No more results</div>
        )
      }
      {/* ADD MODAL */}
      <Modal handleClose={handleCloseModal} showModal={showModal} isTestOnly={editData?.test}>
        <div className='flex  items-start justify-between items-center gap-4'>
          <h1 className="flex-1 text-xl font-bold mb-2">
            Trip Cancellation Request
          </h1>
          <div className='flex flex-row items-center text-right'>
            <Label className="mr-2 mb-0">STATUS:</Label>
            <Chip text={status(editData?.cancellation_status)?.text} type={status(editData?.cancellation_status)?.type} />
          </div>
          {/* <div className="tooltip tooltip-top xl:tooltip-left" data-tip="This will only mark as resolved here in admin dashboard. It will have no effect on apps.">

            <button className='btn btn-success gap-2' onClick={() => alert('update yern')}>Mark as Resolved <FaCheckCircle size="25" /></button>
          </div> */}
        </div>

        <div className='xl:flex gap-8 border-t border-t-black pt-5 mt-5'>
          <div className="w-full xl:w-2/3">
            <div className="-mx-3 md:flex mb-4">
              <Input
                className="md:w-full px-3"
                value={dayjs(editData?.date_created?.toDate()).format('MMM DD, YYYY h:mm a')}
                name="CANCELLATION SUBMITTED AT"

              // placeholder={`${title} description (optional)`}
              // helperText="Make it short. One liner would be good. This will show in-app"
              />
            </div>
            {/* <p>{JSON.stringify(editData?.reporter_data)}</p> */}
            <Label>CANCELLATION SUBMITTED BY DRIVER</Label>
            {/* USER CARD */}
            <div className='flex gap-4 bg-gray-100 p-4 items-center rounded-2 mb-6 rounded-lg text-sm'>
              <div className="avatar bg-gray-400 rounded-full">
                <div className="w-12 rounded-full">
                  <img src={editData?.reporter_data?.profile_picture} />
                </div>
              </div>
              <div className='flex-1'>
                <h3 className='font-bold uppercase'>{editData?.reporter_data?.full_name}</h3>
                <h3>{editData?.reporter_data?.mobile_number}</h3>
              </div>
              <div className=''>
                <a className='btn btn-link btn-secondary text-black' href={`tel:${editData?.reporter_data?.mobile_number}`}>Call</a>
              </div>
            </div>
            <div className="-mx-3 md:flex mb-4">
              <Input
                className="md:w-full px-3"
                value={editData?.report?.title}
                name="title"
              // placeholder={`Title of ${title}`}
              />
            </div>

            <div className="-mx-3 md:flex mb-4">
              <Input
                className="md:w-full px-3"
                value={editData?.report?.description}
                name="Description"

              // placeholder={`${title} description (optional)`}
              // helperText="Make it short. One liner would be good. This will show in-app"
              />
            </div>
            <div className="mb-6">
              <Label>Note from driver</Label>
              <textarea
                className="textarea textarea-bordered border-black w-full"
                value={editData?.report?.reason_note}
              // placeholder={`${title} description (optional)`}
              // helperText="Make it short. One liner would be good. This will show in-app"
              />
            </div>
            {/* USER CARD */}
            <Label>SHIPPER INFO</Label>

            <div className='flex gap-4 bg-gray-100 p-4 items-center rounded-2 mb-6 rounded-lg text-sm'>
              <div className="avatar bg-gray-400 rounded-full">
                <div className="w-12 rounded-full">
                  {
                    editData?.shipper_data?.avatar_url && (
                      <img src={editData?.shipper_data?.avatar_url} />
                    )
                  }
                </div>
              </div>
              <div className='flex-1'>
                <h3 className='font-bold uppercase'>{editData?.shipper_data?.name}</h3>
                <h3>{editData?.shipper_data?.contact_number}</h3>
              </div>
              <div className=''>
                <a className='btn btn-link btn-secondary text-black' href={`tel:${editData?.shipper_data?.contact_number}`}>Call</a>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/3">
            <Label>Actions</Label>
            <div className="flex flex-col gap-4">
              <div className="tooltip tooltip-top xl:tooltip-left" data-tip="This will make the trip available to other driver">
                <button className='btn btn-outline w-full gap-2 btn-success' onClick={handleAccept}
                  disabled={notPending}
                >
                  <FaCheck size="20" />
                  Accept Cancellation <br /> and Reassign</button>
              </div>

              <div className="tooltip tooltip-top xl:tooltip-left" data-tip="The trip will still be assigned to the driver">

                <button className='btn btn-sm btn-outline w-full gap-2 btn-error' onClick={handleReject}
                  disabled={notPending}
                >
                  <FaTimes size="20" />
                  Reject Cancellation</button>
              </div>

              <div className="tooltip tooltip-top xl:tooltip-left" data-tip="This will cancel the trip entirely">
                <a href="#cancel-modal" className='btn btn-sm btn-outline w-full gap-2 btn-error' onClick={handleCancel}

                  disabled={notPending}

                >
                  <FaStopCircle size="20" />
                  Cancel Trip</a>
              </div>

              <hr className='border-black' />
              <a href={`/tracker?id=${editData.trip_id}`} target="_blank" className='btn btn-sm btn-outline w-full gap-2 ' rel="noreferrer"

              >
                <ExternalLink size="20" />
                View trip in tracker</a>


            </div>
          </div>


        </div>

      </Modal>



      <div className="modal" id="cancel-modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Cancel this trip completely</h3>
          <Input
            className="md:w-full -px-3 mt-4"
            name="Message to client"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Input message here"
            helperText="Inform the customer why this trip is cancelled."
          />
          {/* <p className="py-4">Youve been selected for a chance to get one year of subscription to use Wikipedia for free!</p> */}
          <div className="modal-action">
            <a href="#" className="btn btn-link text-red-500 gap-2">Cancel</a>
            <a href="#" className="btn btn-error gap-2" onClick={handleCancel} disabled={message == ''}>    <FaStopCircle size="20" />CANCEL TRIP</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cancellations;