import React, { useCallback, useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import dayjs from 'dayjs';
import GoogleMapReact from 'google-map-react';
// components
import Layout from '../components/layout';
import Toolbar from '../components/toolbar';
import Table from '../components/table';
import Chip from '../components/chips';
import Modal from '../components/modal';
import Loading from '../components/loading';
import Input from '../components/input';
import Label from '../components/label';
import Button from '../components/button';
// import SubscriptionModal from './drivers/subscriptionModal';
import Lightbox from '../components/lightbox';
import { collection, deleteField, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, rtdb } from '../helpers/firebase';
import paginatedFetch from '../helpers/paginatedFetch';
import { ToastContainer, toast } from 'react-toastify';
import { useObjectVal } from 'react-firebase-hooks/database';
import { ref } from 'firebase/database';
import classNames from 'classnames';

const defaultFields = [
  {
    category: 'personal_info',
    name: 'Personal Information',
    select_all: false,
    data: [
      {
        id: 'full_name',
        name: 'Full Name',
        value: false,
      },
      {
        id: 'first_name',
        name: 'First Name',
        value: false,
      },
      {
        id: 'middle_name',
        name: 'Middle Name',
        value: false,
      },
      {
        id: 'last_name',
        name: 'Last Name',
        value: false,
      },
      {
        id: 'suffix',
        name: 'Suffix',
        value: false,
      },
      {
        id: 'birth_date',
        name: 'Birthday',
        value: false,
      },
      {
        id: 'current_address',
        name: 'Address',
        value: false,
      },
      {
        id: 'email',
        name: 'Email',
        value: false,
      },
    ]
  },
  {
    category: 'verification_files',
    name: 'Verification Files',
    select_all: false,
    data: [
      {
        id: 'profileImage',
        name: 'Profile Image',
        value: false,
      },
      {
        id: 'driversLicenseImage',
        name: 'Driver License',
        value: false,
      },
      {
        id: 'validIdImage',
        name: 'Valid ID',
        value: false,
      },
    ]
  },
  {
    category: 'vehicle_info',
    name: 'Vehicle Information',
    select_all: false,
    data: [
      {
        id: 'is_owner',
        name: 'Is Own Vehicle?',
        value: false,
      },
      {
        id: 'make',
        name: 'Make',
        value: false,
      },
      {
        id: 'model',
        name: 'Model',
        value: false,
      },
      {
        id: 'color',
        name: 'Color',
        value: false,
      },
      {
        id: 'plate_number',
        name: 'Plate number',
        value: false,
      },

    ]
  },
  {
    category: 'vehicle_files',
    name: 'Vehicle Files',
    select_all: false,
    data: [
      {
        id: 'certificateRegistration',
        name: 'Certificate of Registration',
        value: false,
      },
      {
        id: 'officialReceipt',
        name: 'Official Receipt',
        value: false,
      },
      {
        id: 'vehicleFront',
        name: 'Vehicle Front',
        value: false,
      },
      {
        id: 'vehicleBack',
        name: 'Vehicle Back',
        value: false,
      },
      {
        id: 'vehicleSide',
        name: 'Vehicle Side',
        value: false,
      },
    ]
  },
]


const Drivers = (params) => {
  const [showModal, setShowModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [image, setImage] = useState(null);
  const [driverData, setDriverData] = useState([]);
  const [data, setData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(dayjs());
  const [updateCounter, setUpdateCounter] = useState(0);
  const [lastKey, setLastKey] = useState('');
  const [nextDataLoading, setNextDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [rejectFields, setRejectFields] = useState(defaultFields);
  const [rejectMessage, setRejectMessage] = useState('');

  const [settings, settingsLoading, settingsError] = useObjectVal(
    ref(rtdb, 'settings')
  );

  const getInitialData = () => {
    paginatedFetch
      .dataFirstBatch('drivers', searchValue)
      .then((res) => {
        setData(res.data);
        // console.log(res.lastKey);
        // console.log('res.lastKey :>> ', res.lastKey);
        setLastKey(res.lastKey);
        setLastUpdated(dayjs());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInitialData();
  }, [searchValue]);


  useEffect(() => {
    // console.log('data changed this happened');
    const getDriverData = () => {
      if (!nextDataLoading) {

        const _data = data && data.map(data => {
          const { mobile_number, full_name, is_verified, user_id, is_fully_verified } = data;

          const status = (is_verified, is_fully_verified) => {
            // console.log('is_verified :>> ', is_verified);
            // not verified, old legacy verification
            // pending legacy verification
            if (is_verified === 'pending' && is_fully_verified !== true) {
              return {
                text: 'pending semi-verification',
                type: 'warning',
                showAsChip: true,
              }
            }
            else if (is_fully_verified === 'pending') {
              return {
                text: 'pending full verification',
                type: 'warning',
                showAsChip: true,
              }
            }
            // partial verification
            else if (is_verified === true && is_fully_verified !== true) {
              return {
                text: 'Semi-Verified',
                type: 'success',
                showAsChip: true,

              }
            }

            // fully verified
            else if (is_verified === true && is_fully_verified === true) {
              return {
                text: 'Fully verified',
                type: 'success',
                showAsChip: true,

              }
            }
            // disabled account
            else if (is_fully_verified === 'rejected') {
              return {
                text: 'Rejected full verification',
                type: 'danger',
                showAsChip: true,

              }
            }
            // disabled account
            else if (is_verified === 'disabled' && is_fully_verified === 'disabled') {
              return {
                text: 'Account disabled',
                type: 'danger',
                showAsChip: true,

              }
            }
            else {
              return {
                text: 'Unverified',
                type: 'danger',
                showAsChip: true,

              }
            }
          }

          // console.log('status :>> ', status);
          return {
            id: user_id,
            full_name,
            mobile_number,
            status: status(is_verified, is_fully_verified)
          }
        });

        setDriverData(_data);
      }
    }
    getDriverData();
    // console.log(data.length);
  }, [data, nextDataLoading])

  useEffect(() => {
    const timer = setInterval(() => setUpdateCounter(updateCounter + 1), 5000);
    return () => clearInterval(timer);
  }, [updateCounter]);

  const fetchMoreData = () => {
    if (lastKey.hasOwnProperty('seconds') || lastKey.length > 0) {
      setNextDataLoading(true);
      paginatedFetch
        .dataNextBatch('drivers', lastKey, searchValue)
        .then((res) => {
          setLastKey(res.lastKey);
          setData(data.concat(res.data));
          setNextDataLoading(false);
          setLastUpdated(dayjs());
        })
        .catch((err) => {
          console.log(err);
          setNextDataLoading(false);
        });
    }
  };


  const tableHeaders = [
    { name: 'Name' },
    { name: 'Mobile Number' },
    { name: 'Status' },
    { name: 'Actions' },
  ];




  // table data

  const handleModalOpen = async (id) => {
    setShowModal(true);

    // console.log('id :>> ', id);

    const docSnap = await getDoc(doc(db, 'drivers', id));
    if (!docSnap.exists()) {
      alert('Driver doesn\'t exist!')
    } else {
      setModal({ id: docSnap.id, ...docSnap.data() });
      // console.log(docSnap.data());
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModal(null);
  };

  const handleFullVerify = (id) => {
    const driverRef = doc(db, 'drivers', id);
    updateDoc(driverRef, {
      is_verified: true,
      is_fully_verified: true,
      account_fully_verified_at: serverTimestamp(),
      account_verified_at: serverTimestamp()
    })
      .then(() => {
        toast.success('Account is now fully verified!');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  const handleSemiVerify = (id) => {
    const driverRef = doc(db, 'drivers', id);
    updateDoc(driverRef, {
      is_verified: true,
      account_verified_at: serverTimestamp(),
      is_fully_verified: false,
      account_fully_verified_at: deleteField(),
    })
      .then(() => {
        toast.success('Account is now semi-verified!');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  const handleFullRejection = (id) => {
    // console.log('id', id)
    // console.log('rejectFields', rejectFields)

    const fields = rejectFields.map((item, i) => {
      const { category, data } = item;
      const _field = data.filter(field => field.value).map(field => {
        if (field.id == 'email' || field.id == 'full_name') {
          return `${field.id}`;
        }
        return `${category}.${field.id}`
      });
      return _field;
    }).flat().reduce((a, v) => ({ ...a, [v]: deleteField() }), {});

    const fieldName = rejectFields.map((item, i) => {
      const { category, data } = item;
      const _field = data.filter(field => field.value).map(field => `${field.name}`);
      return _field;
    }).flat();

    const driverRef = doc(db, 'drivers', id);
    updateDoc(driverRef, {
      is_fully_verified: 'rejected',
      account_fully_verified_at: deleteField(),
      full_verification_submission_date: deleteField(),
      ...fields,
      rejection_details: {
        date: serverTimestamp(),
        message: rejectMessage,
        fields: fieldName
      }
    })
      .then(() => {
        setRejectFields(defaultFields);
        setRejectMessage('');
        toast.success('Full verification rejected!');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }
  const handleSemiRejection = (id) => {
    const driverRef = doc(db, 'drivers', id);
    updateDoc(driverRef, {
      is_fully_verified: deleteField(),
      account_fully_verified_at: deleteField(),
      account_verified_at: deleteField(),
      is_verified: false,
      full_verification_submission_date: deleteField(),
    })
      .then(() => {
        toast.success('Semi-verification rejected!');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  const handleUnverify = (id) => {
    const driverRef = doc(db, 'drivers', id);

    updateDoc(driverRef, {
      is_verified: false,
      is_fully_verified: false,
      account_verified_at: deleteField(),
      account_fully_verified_at: deleteField(),
      vehicle_files: deleteField(),
      vehicle_info: deleteField(),
      personal_info: deleteField(),
      full_verification_submission_date: deleteField(),
      verification_files: deleteField(),
    })
      .then(() => {
        toast.success('Account now unverified');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  const handleDisabled = (id) => {
    const driverRef = doc(db, 'drivers', id);

    let data = {};

    data = {
      is_verified: 'disabled',
      is_fully_verified: 'disabled',
      account_verified_at: deleteField(),
      account_fully_verified_at: deleteField(),
    }

    if (modal?.is_verified == 'disabled') {
      data = {
        is_verified: false,
        is_fully_verified: false,
      }
    }

    updateDoc(driverRef, data)
      .then(() => {
        toast.success('Account now unverified');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  const handleMakeTester = (id, isTester) => {
    const driverRef = doc(db, 'drivers', id);

    updateDoc(driverRef, {
      tester_account: !isTester,
    })
      .then(() => {
        toast.success("Account type updated. User must relogin for changes to take effect.");
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  const handleVehicleChange = (id) => {
    const driverRef = doc(db, 'drivers', id);
    updateDoc(driverRef, {
      vehicle_type: selectedVehicle,
      vehicle_info: deleteField(),
      vehicle_files: deleteField(),
      is_verified: false,
      is_fully_verified: false,
      account_verified_at: '',
      is_fully_verified: 'rejected',
      rejection_details: {
        date: serverTimestamp(),
        message: 'Vehicle type is changed. Please resubmit necessary docs.'
      }
    })
      .then(() => {
        toast.success('Vehicle changed, account unverified.');
        handleCloseModal();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }


  const actions = [
    {
      name: 'View',
      action: handleModalOpen
    },
  ];

  const handleSearch = (value) => {
    setSearchValue(value);
  }


  return (
    <div className='font-admin'>
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-2">
        Drivers
      </h1>
      <Toolbar searchHandler={handleSearch} searchPlaceholder="Type a driver name and press enter..." />
      <Table headers={tableHeaders} data={driverData} rowActions={actions} highlight={searchValue} />

      <Modal handleClose={handleCloseModal} showModal={showModal} isTester={modal?.tester_account}>
        {
          modal ? (
            <>
              {/* <p>{modal?.user_id}</p> */}
              <h1 className="flex-1 text-xl font-bold mb-2">
                Driver Details
              </h1>
              <div className='xl:flex gap-8 border-t border-t-black pt-5'>
                {/* left */}

                <div className="w-full xl:w-2/3">
                  <div className="divider text-grey-darker text-xs font-bold uppercase before:bg-black after:bg-black">
                    Personal Information
                  </div>
                  <div className="-mx-3 md:flex mb-6">
                    <Input
                      // className="px-0"
                      name="Full name"
                      id="full_name"
                      value={modal?.full_name}
                      placeholder="Driver full name"
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-6 mb-6">
                    <Input
                      className="md:w-1/2"
                      name="First Name"
                      id="personal_info.first_name"
                      value={modal?.personal_info?.first_name}
                      placeholder="First Name"
                    />
                    <Input
                      className="md:w-1/2"
                      name="middle Name"
                      id="personal_info.middle_name"
                      value={modal?.personal_info?.middle_name}
                      placeholder="N/A"
                    />
                    <Input
                      className="md:w-1/2"
                      name="last Name"
                      id="personal_info.last_name"
                      value={modal?.personal_info?.last_name}
                      placeholder="last Name"
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-6 mb-6">
                    <Input
                      className="md:w-1/4"
                      name="suffix Name"
                      id="personal_info.suffix_name"
                      value={modal?.personal_info?.suffix_name}
                      placeholder="N/A"
                    />
                  </div>
                  <div className="divider text-grey-darker text-xs font-bold uppercase before:bg-black after:bg-black">
                    CONTACT INFORMATION
                  </div>
                  <div className="flex flex-col md:flex-row gap-6 mb-6">
                    <Input
                      className="md:w-full"
                      name="Current Address"
                      id="personal_info.current_address"
                      value={modal?.personal_info?.current_address}
                      placeholder="Address"
                    // disabled
                    />

                  </div>
                  <div className="flex flex-col md:flex-row gap-6 mb-6">
                    <Input
                      className="md:w-1/2"
                      name="Mobile Number"
                      id="mobile_number"
                      value={modal?.mobile_number}
                      placeholder="Driver full name"
                    // disabled
                    />
                    <Input
                      className="md:w-1/2"
                      name="email"
                      id="email"
                      value={modal?.email}
                      placeholder="No email address"
                    />
                  </div>
                  <div className="divider text-grey-darker text-xs font-bold uppercase before:bg-black after:bg-black">
                    ACCOUNT DETAILS
                  </div>
                  <div className="flex items-end gap-3 mb-6">
                    <Input
                      className=""
                      name="Vehicle Type"
                      id="vehicle_type"
                      value={modal?.vehicle_type}
                      placeholder="Vehicle type"
                    />
                    <a href="#change-vehicle-modal" className='btn btn-primary btn-sm mb-2'>Change</a>

                  </div>
                  <div className="-mx-3 md:flex mb-6">
                    <Input
                      className="md:w-2/3 px-3"
                      name="user id"
                      id="user_id"
                      value={modal?.user_id}
                      placeholder="User ID missing"
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-6 mb-6">
                    <Input
                      className="md:w-1/2"
                      id="account_created_at"
                      value={modal?.account_created_at?.toDate()}
                      placeholder="No email address"
                    />
                    <Input
                      className="md:w-1/2"
                      id="account_verified_at"
                      value={modal?.account_verified_at && modal?.account_verified_at.toDate()}
                      placeholder="Not yet verified"
                    />
                  </div>
                  <div className="divider text-grey-darker text-xs font-bold uppercase before:bg-black after:bg-black">
                    VEHICLE INFORMATION
                  </div>
                  <div className="flex flex-col md:flex-row gap-6 mb-6">
                    <Input
                      className="md:w-1/2"
                      name="model"
                      id="make"
                      value={modal?.vehicle_info?.make}
                      placeholder="Make"
                    />
                    <Input
                      className="md:w-1/2"
                      name="make"
                      id="model"
                      value={modal?.vehicle_info?.model}
                      placeholder="Model"
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-6 mb-6">
                    <Input
                      className="md:w-1/2"
                      name="color"
                      id="color"
                      value={modal?.vehicle_info?.color}
                      placeholder="Color"
                    />
                    <Input
                      className="md:w-1/2"
                      name="Plate number"
                      id="plate_number"
                      value={modal?.vehicle_info?.plate_number}
                      placeholder="Color"
                    />
                  </div>
                  <div className="flex flex-col md:flex-row gap-6 mb-6">
                    <Input
                      className="md:w-1/3"
                      name="Is vehicle owned?"
                      id="color"
                      value={modal?.vehicle_info?.is_owner ? 'Yes' : 'No'}
                    />
                  </div>
                </div>
                {/* right */}
                <div className="w-full xl:w-1/3">
                  <div className="mb-6">
                    <Label>STATUS</Label>
                    {
                      modal?.is_verified == true && <Chip text="Semi-Verified" type="success" />
                    }
                    {
                      modal?.is_verified == 'pending' && <Chip text="pending semi-verification" type="warning" />
                    }
                    {
                      modal?.is_verified === false && <Chip text="Unverified" type="danger" />
                    }
                    {
                      modal?.is_fully_verified === false && <Chip text="Not fully verified" type="warning" />
                    }
                    {
                      (modal?.is_verified == 'disabled' || modal?.is_fully_verified == 'disabled') && <Chip text="Account disabled" type="danger" />
                    }
                    {
                      modal?.is_fully_verified === true && <Chip text="Fully Verified" type="success" />
                    }
                    {
                      modal?.is_fully_verified === 'pending' && <Chip text="pending full verication" type="warning" />
                    }
                  </div>
                  <div className="mb-6">
                    <Label>SUBMITTED DOCUMENTS</Label>
                    <div className="divider text-grey-darker text-xs font-bold uppercase before:bg-black after:bg-black">Verification Files</div>
                    {
                      modal?.verification_files && Object.entries(modal?.verification_files).length > 0 ? Object.entries(modal?.verification_files).map(file => {
                        return (
                          <>
                            {
                              file[1] && (
                                <div className="flex justify-between mb-1 hover:bg-gray-100">
                                  <p className="capitalize p-2">{file[0].split(/(?=[A-Z])/).join(' ')}</p>
                                  <button className='btn btn-outline btn-sm' variant="outline" size="sm" color="blue" onClick={() => setImage(file[1])}>View</button>
                                </div>
                              )
                            }
                          </>
                        )
                      }) : <p className="text-gray-400">No submitted documents yet.</p>
                    }
                    <div className="divider text-grey-darker text-xs font-bold uppercase before:bg-black after:bg-black">Vehicle Images</div>
                    {
                      modal?.vehicle_files ? Object.entries(modal?.vehicle_files).filter(file => file[0].includes('vehicle')).map(file => {
                        return (
                          <>
                            {
                              file[1] && (
                                <div className="flex justify-between mb-1 hover:bg-gray-100">
                                  <p className="capitalize p-2">{file[0].split(/(?=[A-Z])/).join(' ')}</p>
                                  <button className='btn btn-outline btn-sm' variant="outline" size="sm" color="blue" onClick={() => setImage(file[1])}>View</button>
                                </div>
                              )
                            }
                          </>
                        )
                      }) : <p className="text-gray-400">No submitted documents yet.</p>
                    }
                    <div className="divider text-grey-darker text-xs font-bold uppercase before:bg-black after:bg-black">Vehicle Papers</div>

                    {
                      modal?.vehicle_files ? Object.entries(modal?.vehicle_files).filter(file => !file[0].includes('vehicle')).map(file => {
                        return (
                          <>
                            {
                              file[1] && (
                                <div className="flex justify-between mb-1 hover:bg-gray-100">
                                  <p className="capitalize p-2">{file[0].split(/(?=[A-Z])/).join(' ')}</p>
                                  <button className='btn btn-outline btn-sm' variant="outline" size="sm" color="blue" onClick={() => setImage(file[1])}>View</button>
                                </div>
                              )
                            }
                          </>
                        )
                      }) : <p className="text-gray-400">No submitted documents yet.</p>
                    }
                  </div>
                  <div className="flex flex-col gap-2">
                    <Label>Actions</Label>
                    <div className="tooltip tooltip-top xl:tooltip-left" data-tip="Makes this driver fully verified. Make sure that all documents are submitted.">
                      <button disabled={modal?.is_fully_verified !== 'pending'} className='btn btn-success w-full ' onClick={() => handleFullVerify(modal?.user_id)}>Set as Fully Verified Account</button>
                    </div>
                    <div className="tooltip tooltip-top xl:tooltip-left" data-tip="For drivers that only submitted their personal documents and IDs but without Vehicle details yet. This is prior to August 2022 update. A message will pop-up on their app to submit other documents.">
                      <button
                        className='btn w-full btn-outline '
                        onClick={() => handleSemiVerify(modal?.user_id)}
                        disabled={modal?.is_verified !== 'pending'}
                      >
                        Set as Semi-Verified Account
                      </button>
                    </div>
                    <div className="tooltip tooltip-top xl:tooltip-left" data-tip="Reject the FULL verification and state the reason why their verification is rejected. Drivers will be able to edit their verification">
                      <a
                        disabled={modal?.is_fully_verified !== 'pending'}
                        className='btn w-full btn-error btn-outline '
                        href="#reject-full-verification"
                      // onClick={() => handleVerify(modal?.user_id)}
                      >Reject full verification</a>
                    </div>
                    <div className="tooltip tooltip-top xl:tooltip-left" data-tip="Reject the SEMI verification and state the reason why their verification is rejected. Drivers will be able to edit their verification">
                      <button disabled={modal?.is_verified !== 'pending'} className='btn w-full btn-error btn-outline ' onClick={() => handleSemiRejection(modal?.user_id)}>Reject semi-verification</button>
                    </div>
                    <div className="tooltip tooltip-top xl:tooltip-left" data-tip="Set this account as unverified. This will remove existing driver details and vehicle info. Drivers should resubmit their verification">
                      <button className='btn w-full btn-error btn-outline' disabled={modal?.is_verified === 'disabled'} onClick={() => handleUnverify(modal?.user_id)}>Set as unverified and remove info</button>
                    </div>
                    <div className="tooltip tooltip-top xl:tooltip-left" data-tip="Completely disables the account. Driver won't be able to resubmit verification documents">
                      <button className={classNames('btn w-full', modal?.is_verified !== 'disabled' ? 'btn-error' : 'btn-success')} onClick={() => handleDisabled(modal?.user_id)}>
                        {
                          modal?.is_verified === 'disabled' ?
                            'Re-enable Account' : 'Disable Account'
                        }


                      </button>
                    </div>

                    <hr className='my-4' />
                    <button className='btn btn-outline btn-secondary' onClick={() => handleMakeTester(modal?.user_id, modal?.tester_account)}>Toggle tester account</button>
                  </div>
                </div>
              </div>

            </>
          ) : (
            <Loading className="max-h-10 max-w-10 self-center" />
          )
        }


      </Modal>
      {
        lastKey != '' || lastKey.hasOwnProperty('seconds') ? (
          <button className="btn btn-secondary mt-5" disabled={nextDataLoading} onClick={() => fetchMoreData()}>
            {
              nextDataLoading ? 'Loading...' : 'Load more'
            }
          </button>
        ) : (
          <div className='mt-2 text-gray-300'>No more results</div>
        )
      }
      <Lightbox handleClose={() => { setImage(null) }} visible={image} images={[image]} />


      <div className="modal" id="change-vehicle-modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Change Vehicle</h3>
          <div className="alert shadow-lg my-5">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
              <span>This action will unverify the driver account. They will need to resubmit their vehicle images and papers.</span>
            </div>
          </div>
          <p></p>
          <select className="select select-bordered w-full" onChange={e => setSelectedVehicle(e.target.value)}>
            <option disabled selected>Choose a Pinbuggy</option>
            {!settingsLoading && settings?.vehicle_types && Object.entries(settings.vehicle_types).map((v, i) => {
              return (
                <option key={i}>{v[1].identifier}</option>
              )
            })}
          </select>
          {/* <p className="py-4">Youve been selected for a chance to get one year of subscription to use Wikipedia for free!</p> */}
          <div className="modal-action">
            <a href="#" className="btn btn-link text-red-500 gap-2">Cancel</a>
            <a href="#" className="btn btn-primary gap-2" disabled={!selectedVehicle} onClick={() => handleVehicleChange(modal?.user_id)}>Confirm Change Vehicle</a>
          </div>
        </div>
      </div>

      {/* Reject modal */}
      <div className="modal" id="reject-full-verification">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Reject Full Verification</h3>
          <div className="alert shadow-lg my-5">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
              <span>Select the fields that needs to be resubmitted</span>
            </div>
          </div>

          {
            rejectFields.map(({ category, data, select_all, name }, catIndex) => {
              return <div key={catIndex} className='mb-4'>
                <label className='flex items-center gap-2 border-t border-t-black pt-2 my-2'>
                  <input type="checkbox" checked={select_all} className='checkbox' onChange={
                    e => {
                      let newState = [...rejectFields];
                      let newCategoryData = newState[catIndex].data.map(field => {
                        const newField = { ...field };
                        newField.value = e.target.checked;
                        return newField;
                      });
                      newState[catIndex].select_all = e.target.checked;
                      newState[catIndex].data = [...newCategoryData];
                      setRejectFields(newState);
                    }
                  } />
                  <h2 className='font-bold'>{name}</h2>
                </label>

                {
                  data.map(({ id, value, name }, i) => {
                    return (
                      <label key={i} className='flex items-center gap-2 my-2'>
                        <input type="checkbox" checked={value} className='checkbox' onChange={
                          e => {
                            let newState = [...rejectFields];
                            newState[catIndex].data[i].value = e.target.checked;
                            setRejectFields(newState);
                          }
                        } />
                        {name}
                      </label>
                    )
                  })
                }
              </div>
            })
          }

          {/* <p></p> */}
          {/* <select className="select select-bordered w-full" onChange={e => setSelectedVehicle(e.target.value)}>
            <option disabled selected>Choose a Pinbuggy</option>
            {!settingsLoading && settings?.vehicle_types && Object.entries(settings.vehicle_types).map((v, i) => {
              return (
                <option key={i}>{v[1].identifier}</option>
              )
            })}
          </select> */}

          <Input
            className="md:w-full -px-3 mt-4"
            name="Message to driver"
            value={rejectMessage}
            onChange={e => setRejectMessage(e.target.value)}
            placeholder="Input message here"
            helperText="Inform the driver why his verification is rejected."
          />
          {/* <p className="py-4">Youve been selected for a chance to get one year of subscription to use Wikipedia for free!</p> */}
          <div className="modal-action">
            <a href="#" className="btn btn-link text-red-500 gap-2">Cancel</a>
            <a href="#" className="btn btn-primary gap-2"
              onClick={() => handleFullRejection(modal?.user_id)}
            >Reject</a>
          </div>
        </div>
      </div>
      {/* <SubscriptionModal handleClose={() => setShowSubscriptionModal(false)} showModal={showSubscriptionModal} /> */}
    </div>
  )
}

export default Drivers;