import React, { useEffect, useState } from 'react';

// components
import Toolbar from '../components/toolbar';
import Table from '../components/table';
import reasonsPaginatedFetch from '../helpers/reasonsPaginatedFetch';
import dayjs from 'dayjs';
import Modal from '../components/modal';
import Input from '../components/input';
import Label from '../components/label';
import { FaCheck, FaCheckCircle, FaStop, FaStopCircle, FaTimes } from 'react-icons/fa';
import { ExternalLink } from 'react-feather';
import { Link } from 'gatsby';
import Chip from '../components/chips';
import { deleteField, doc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { db } from '../helpers/firebase';
import { toast, ToastContainer } from 'react-toastify';


const ReportedDrivers = (params) => {
  const [showModal, setShowModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [image, setImage] = useState(null);
  const [data, setData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(dayjs());
  const [updateCounter, setUpdateCounter] = useState(0);
  const [lastKey, setLastKey] = useState('');
  const [nextDataLoading, setNextDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [editData, setEditData] = useState({ loading: true });
  const [message, setMessage] = useState('');

  const getInitialData = () => {
    reasonsPaginatedFetch
      .dataFirstBatch('driver_reports', searchValue)
      .then((res) => {
        setData(res.data);
        setLastKey(res.lastKey);
        setLastUpdated(dayjs());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInitialData();
  }, [searchValue]);


  useEffect(() => {
    const timer = setInterval(() => setUpdateCounter(updateCounter + 1), 5000);
    return () => clearInterval(timer);
  }, [updateCounter]);

  const fetchMoreData = () => {
    if (lastKey.hasOwnProperty('seconds') || lastKey.length > 0) {
      setNextDataLoading(true);
      reasonsPaginatedFetch
        .dataNextBatch('driver_reports', lastKey, searchValue)
        .then((res) => {
          setLastKey(res.lastKey);
          setData(data.concat(res.data));
          setNextDataLoading(false);
          setLastUpdated(dayjs());
        })
        .catch((err) => {
          console.log(err);
          setNextDataLoading(false);
        });
    }
  };


  const handleSearch = (value) => {
    setSearchValue(value);
  }


  const tableHeaders = [
    { name: 'Report Title' },
    { name: 'Report Date' },
    { name: 'Reported Driver' },
    { name: 'Status' },
    { name: 'Action' },
  ];


  const status = (report_status) => {
    if (report_status == 'pending') {
      return {
        text: 'pending',
        type: 'warning',
        showAsChip: true,

      }
    }
    else if (report_status == 'resolved') {
      return {
        text: 'resolved',
        type: 'success',
        showAsChip: true,
      }
    }
    else if (report_status == 'rejected') {
      return {
        text: 'rejected',
        type: 'error',
        showAsChip: true,
      }
    }
    // else {
    //   return {
    //     text: 'unverified',
    //     type: 'danger',
    //     showAsChip: true,

    //   }
    // }
  }

  // table data
  const tableData = data && data.map((_data, i) => {
    const { date_created, report_status, driver_data, report } = _data;


    return {
      index: i,
      report_title: report.title,
      date_created: dayjs(date_created.toDate()).format('MMM DD, YYYY h:mm a'),
      driver: driver_data.name,
      status: status(report_status)
    }
  })



  const handleCloseModal = () => {
    setShowModal(false);
    setEditData({ loading: true })
  }

  const handleModalOpen = async (index) => {

    // console.log('data[index]', data[index])
    setShowModal(true);
    setEditData({
      ...data[index],
      // index,
      loading: false
    });

  };

  const markAsResolved = async () => {
    if (!message) return;
    const tripData = {
      resolved_message: message,
      resolved_at: serverTimestamp(),
      report_status: 'resolved',
      user_cancellation_rejected: deleteField()
    }

    const reportData = {
      report_status: 'resolved',
      resolved: {
        resolved_message: message,
        resolved_at: serverTimestamp(),
        report_status: 'resolved',
      },
    }
    const TRIP_COLLECTION = editData?.mock_only ? 'mock_trips' : 'trips';

    try {
      const batch = writeBatch(db);
      const tripRef = doc(db, TRIP_COLLECTION, editData?.trip_id);
      const reportRef = doc(db, "driver_reports", editData?.id);
      batch.update(tripRef, tripData);
      batch.update(reportRef, reportData);
      await batch.commit();
      toast.success('Report now marked as resolved!');
      getInitialData();

      //@TODO
      //reload the page
      // window.location.reload();

    } catch (error) {
      toast.error('Operation failed');
      console.log(error);

    }
    finally {
      setMessage('');
      handleCloseModal();
    }


  }

  // useEffect(() => {
  //   console.log(data);
  // }, [data])

  const actions = [
    {
      name: 'View',
      action: handleModalOpen
    },
  ]


  return (
    <div className='font-admin'>
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-2">
        Reported Drivers
      </h1>

      {/* <Toolbar actionTitle="Add" action={() => setModalVisible(!isModalVisible)} searchHandler={handleSearch} searchPlaceholder="Type a user name and press enter..." /> */}
      <Table headers={tableHeaders} data={tableData} rowActions={actions} />
      {
        lastKey != '' || lastKey.hasOwnProperty('seconds') ? (
          <button className="btn btn-secondary mt-5" disabled={nextDataLoading} onClick={() => fetchMoreData()}>
            {
              nextDataLoading ? 'Loading...' : 'Load more'
            }
          </button>
        ) : (
          <div className='mt-2 text-gray-300'>No more results</div>
        )
      }
      {/* ADD MODAL */}
      <Modal handleClose={handleCloseModal} showModal={showModal} isTestOnly={editData?.mock_only}>
        <div className='flex  items-start justify-between items-center gap-4'>
          <h1 className="flex-1 text-xl font-bold mb-2">
            Report Details
          </h1>
          <div className='flex flex-row items-center text-right'>
            <Label className="mr-2 mb-0">STATUS:</Label>
            <Chip text={status(editData?.report_status)?.text} type={status(editData?.report_status)?.type} />
          </div>
          {/* <div className="tooltip tooltip-top xl:tooltip-left" data-tip="This will only mark as resolved here in admin dashboard. It will have no effect on apps.">

            <button className='btn btn-success gap-2' onClick={() => alert('update yern')}>Mark as Resolved <FaCheckCircle size="25" /></button>
          </div> */}
        </div>

        <div className='xl:flex gap-8 border-t border-t-black pt-5 mt-5'>
          <div className="w-full xl:w-2/3">
            <div className="-mx-3 md:flex mb-4">
              <Input
                className="md:w-full px-3"
                value={dayjs(editData?.date_created?.toDate()).format('MMM DD, YYYY h:mm a')}
                name="REPORT SUBMITTED AT"

              // placeholder={`${title} description (optional)`}
              // helperText="Make it short. One liner would be good. This will show in-app"
              />
            </div>
            {/* <p>{JSON.stringify(editData?.reporter_data)}</p> */}
            <Label>SUBMITTED BY</Label>
            {/* USER CARD */}
            <div className='flex gap-4 bg-gray-100 p-4 items-center rounded-2 mb-6 rounded-lg text-sm'>
              <div className="avatar bg-gray-400 rounded-full">
                <div className="w-12 rounded-full">
                  {
                    editData?.reporter_data?.profile_picture && (
                      <img src={editData?.reporter_data?.profile_picture} />
                    )
                  }
                </div>
              </div>
              <div className='flex-1'>
                <h3 className='font-bold uppercase'>{editData?.reporter_data?.full_name}</h3>
                <h3>{editData?.reporter_data?.mobile_number}</h3>
              </div>
              <div className=''>
                <a className='btn btn-link btn-secondary text-black' href={`tel:${editData?.reporter_data?.mobile_number}`}>Call</a>
              </div>
            </div>
            <div className="-mx-3 md:flex mb-4">
              <Input
                className="md:w-full px-3"
                value={editData?.report?.title}
                name="title"
              // placeholder={`Title of ${title}`}
              />
            </div>

            <div className="-mx-3 md:flex mb-4">
              <Input
                className="md:w-full px-3"
                value={editData?.report?.description}
                name="Description"

              // placeholder={`${title} description (optional)`}
              // helperText="Make it short. One liner would be good. This will show in-app"
              />
            </div>
            <div className="mb-6">
              <Label>REPORT REASON</Label>
              <textarea
                className="textarea textarea-bordered border-black w-full"
                value={editData?.report?.reason_note}
              />
            </div>
            {/* USER CARD */}
            <Label>REPORTED DRIVER INFO</Label>

            <div className='flex gap-4 bg-gray-100 p-4 items-center rounded-2 mb-6 rounded-lg text-sm'>
              <div className="avatar bg-gray-400 rounded-full">
                <div className="w-12 rounded-full">
                  {
                    editData?.driver_data?.avatar_url && (
                      <img src={editData?.driver_data?.avatar_url} />
                    )
                  }
                </div>
              </div>
              <div className='flex-1'>
                <h3 className='font-bold uppercase'>{editData?.driver_data?.name}</h3>
                <h3>{editData?.driver_data?.mobile_number}</h3>
              </div>
              <div className=''>
                <a className='btn btn-link btn-secondary text-black' href={`tel:${editData?.shipper_data?.mobile_number}`}>Call</a>
              </div>
            </div>
            {
              editData?.resolved && (
                <>
                  <div className="mb-6">
                    <Label>ADMIN RESOLVED MESSAGE</Label>
                    <textarea
                      className="textarea textarea-bordered border-black w-full"
                      value={editData?.resolved?.resolved_message}
                    />
                  </div>
                  <div className="mb-6">

                    <Input
                      className="md:w-full -px-3"
                      value={dayjs(editData?.resolved?.resolved_at?.toDate()).format('MMM DD, YYYY h:mm a')}

                      name="Admin resolved at"
                    // placeholder={`Title of ${title}`}
                    />
                  </div></>
              )
            }

          </div>
          <div className="w-full xl:w-1/3">
            <Label>Actions</Label>
            <div className="flex flex-col gap-4">
              <div className="tooltip tooltip-top xl:tooltip-left" data-tip="This will show to the one who made this report as resolved.">
                <a href="#confirm-modal" className='btn btn-outline w-full gap-2 btn-success' disabled={editData?.report_status == 'resolved'}>
                  <FaCheck size="20" />
                  Mark as Resolved</a>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="modal" id="confirm-modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Mark this report as resolved</h3>
          <Input
            className="md:w-full -px-3 mt-4"
            name="Message to Reporter"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Input message here"
            helperText="Inform the reportee that you marked this report as resolved"
          />
          {/* <p className="py-4">Youve been selected for a chance to get one year of subscription to use Wikipedia for free!</p> */}
          <div className="modal-action">
            <a href="#" className="btn btn-link text-red-500 gap-2">Cancel</a>
            <a href="#" className="btn btn-success gap-2" onClick={markAsResolved} disabled={message == ''}>    <FaCheck size="20" />MARK AS RESOLVED</a>
          </div>
        </div>
      </div>
    </div >
  )
}

export default ReportedDrivers;