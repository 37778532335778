import classNames from 'classnames';
import React from 'react'
import Highlight from 'react-highlighter';
import Button from './button';
import Chip from './chips';

const TableRow = ({ data, rowActions, highlight }) => {
  if (!data) return null;

  return (
    <tr className='hover:bg-gray-100'>
      {Object.entries(data).map((data, i) => {
        if (data[0] == 'id' || data[0] == 'index') return null;

        if (!!data[1]?.showAsChip) {
          return (
            <td key={i} className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
              <div className="flex items-center">
                <div>
                  <Chip text={data[1].text} type={data[1].type} />
                </div>
              </div>
            </td>
          )
        }
        return (
          <td key={i} className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
            <div className="flex items-center">
              <div>
                <div className="text-sm leading-5 text-black font-admin">
                  <Highlight search={highlight}>
                    {data[1]}
                  </Highlight>
                </div>
              </div>
            </div>
          </td>
        )
      })}
      {/* ACTIONS */}
      {rowActions && (
        <td className="border-b border-gray-500">{
          rowActions.map((el, i) => {
            return (
              <button className={classNames('btn btn-outline btn-sm font-admin mx-1', el?.btnType)} key={i} size="small" variant="outline" disabled={el?.disabled} onClick={() => {

                if (data.id) {
                  el.action(data.id);
                }
                else if (data.hasOwnProperty('index')) {
                  el.action(data.index);
                }


                // console.log('action', el.action(data.id))
              }}>{el.name}</button>
            )
          })
        }</td>
      )}

    </tr>
  )
}

export default TableRow;
