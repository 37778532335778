import React, { useEffect, useState } from 'react';

// components
import Toolbar from '../components/toolbar';
import Table from '../components/table';
import paginatedFetch from '../helpers/paginatedFetch';
import dayjs from 'dayjs';
import Label from '../components/label';
import Loading from '../components/loading';
import Input from '../components/input';
import Modal from '../components/modal';
import { toast, ToastContainer } from 'react-toastify';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../helpers/firebase';


const Users = (params) => {
  const [showModal, setShowModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [image, setImage] = useState(null);
  const [userData, setUserData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(dayjs());
  const [updateCounter, setUpdateCounter] = useState(0);
  const [lastKey, setLastKey] = useState('');
  const [nextDataLoading, setNextDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [editData, setEditData] = useState({ loading: true });

  const getInitialData = () => {
    paginatedFetch
      .dataFirstBatch('users', searchValue)
      .then((res) => {
        setUserData(res.data);
        setLastKey(res.lastKey);
        setLastUpdated(dayjs());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInitialData();
  }, [searchValue]);


  // useEffect(() => {
  //   console.log('data changed this happened');
  //   const getDriverData = () => {
  //     if (!nextDataLoading) {

  //       const _data = data && data.map(data => {
  //         const { mobile_number, full_name, is_verified, user_id } = data;

  //         const status = (is_verified) => {
  //           // console.log('is_verified :>> ', is_verified);
  //           if (is_verified == 'pending') {

  //             return {
  //               text: 'pending',
  //               type: 'warning',
  //               showAsChip: true,

  //             }
  //           }
  //           else if (is_verified) {
  //             return {
  //               text: 'verified',
  //               type: 'success',
  //               showAsChip: true,

  //             }
  //           }
  //           else {
  //             return {
  //               text: 'unverified',
  //               type: 'danger',
  //               showAsChip: true,

  //             }
  //           }
  //         }

  //         // console.log('status :>> ', status);
  //         return {
  //           id: user_id,
  //           full_name,
  //           mobile_number,
  //           status: status(is_verified)
  //         }
  //       });

  //       setUserData(_data);
  //     }
  //   }
  //   getDriverData();
  //   console.log(data.length);
  // }, [data, nextDataLoading])

  useEffect(() => {
    const timer = setInterval(() => setUpdateCounter(updateCounter + 1), 5000);
    return () => clearInterval(timer);
  }, [updateCounter]);


  // useEffect(() => {
  //   console.log('lastKey :>> ', lastKey.seconds);
  //   console.log(userData);
  // }, [lastKey]);

  const fetchMoreData = () => {
    if (lastKey.hasOwnProperty('seconds') || lastKey.length > 0) {
      setNextDataLoading(true);
      // console.log('fetchmoredata :>> ', lastKey.seconds);
      paginatedFetch
        .dataNextBatch('users', lastKey, searchValue)
        .then((res) => {
          setLastKey(res.lastKey);
          setUserData(data.concat(res.data));
          setNextDataLoading(false);
          setLastUpdated(dayjs());
        })
        .catch((err) => {
          console.log(err);
          setNextDataLoading(false);
        });
    }
  };


  const handleSearch = (value) => {
    setSearchValue(value);
  }


  const tableHeaders = [
    { name: 'Name' },
    { name: 'Email' },
    { name: 'Creation date' },
    { name: 'Actions' },
  ];

  // table data
  const data = userData && userData.map((users, i) => {
    const { full_name, email, account_created_at } = users;
    return {
      index: i,
      full_name,
      email,
      date: dayjs(account_created_at?.toDate ? account_created_at.toDate() : account_created_at).format('MMM DD, YYYY h:mm a')
    }
  });

  const handleModalOpen = async (index) => {

    // console.log('data[index]', data[index])
    setShowModal(true);
    setEditData({
      ...userData[index],
      // index,
      loading: false
    });

  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditData({ loading: true })
  }

  const actions = [
    {
      name: 'View',
      action: handleModalOpen
    },
  ];

  const handleMakeTester = (id, isTester) => {
    const usersRef = doc(db, 'users', id);

    updateDoc(usersRef, {
      tester_account: !isTester,
    })
      .then(() => {
        toast.success("Account type updated. User must relogin for changes to take effect.");
        handleCloseModal();
        getInitialData();
      })
      .catch(err => toast.error('Something wen\'t wrong!'));
  }

  return (
    <div className='font-admin'>
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-2">
        Users
      </h1>
      <Toolbar actionTitle="Add" action={() => setModalVisible(!isModalVisible)} searchHandler={handleSearch} searchPlaceholder="Type a user name and press enter..." />
      <Table headers={tableHeaders} data={data} rowActions={actions} />
      {
        lastKey != '' || lastKey.hasOwnProperty('seconds') ? (
          <button className="btn btn-secondary mt-5" disabled={nextDataLoading} onClick={() => fetchMoreData()}>
            {
              nextDataLoading ? 'Loading...' : 'Load more'
            }
          </button>
        ) : (
          <div className='mt-2 text-gray-300'>No more results</div>
        )
      }
      <Modal handleClose={handleCloseModal} showModal={showModal} isTester={editData?.tester_account}>
        {
          editData ? (
            <>
              {/* <p>{editData?.user_id}</p> */}
              <h1 className="flex-1 text-xl font-bold mb-2">
                User Details
              </h1>
              <div className="-mx-3 md:flex mb-6">

                <Input
                  className="md:w-2/3 px-3"
                  name="Full name"
                  id="full_name"
                  value={editData?.full_name}
                  placeholder="User full name"
                />
              </div>
              <div className="-mx-3 md:flex mb-6">
                <Input
                  className="md:w-1/2 px-3"
                  name="Mobile Number"
                  id="mobile_number"
                  value={editData?.mobile_number}
                  placeholder="Mobile Number"
                // disabled
                />
                <Input
                  className="md:w-1/2 px-3"
                  name="email"
                  id="email"
                  value={editData?.email}
                  placeholder="No email address"
                />
              </div>
              {/* <div className="-mx-3 md:flex mb-6">
                <Input
                  name="address"
                  id="address"
                  value={editData?.address}
                  placeholder="Driver address"
                />
              </div> */}
              <div className="-mx-3 md:flex mb-6">

                <Input
                  className="md:w-1/2 px-3"
                  id="account_created_at"
                  value={editData?.account_created_at?.toDate()}
                  placeholder="No email address"
                />
                <Input
                  className="md:w-1/2 px-3"
                  id="account_verified_at"
                  value={editData?.account_verified_at && editData?.account_verified_at.toDate()}
                  placeholder="Not yet verified"
                />
              </div>
              <div className="flex -mx-3">
                <div className="md:w-1/2 px-3">
                  <Label>Actions</Label>
                  <button className='btn btn-outline btn-secondary mt-4' onClick={() => handleMakeTester(editData?.user_id, editData?.tester_account)}>Toggle tester account</button>
                </div>
              </div>
            </>
          ) : (
            <Loading className="max-h-10 max-w-10 self-center" />
          )
        }


      </Modal>
    </div>
  )
}

export default Users;