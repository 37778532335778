import React from 'react'
import TableHeader from './tableHeader';
import TableRow from './tableRow';


const Table = ({ headers, data, rowActions, loading, highlight }) => {
  if (loading) return <p className="py-3">Loading...</p>
  if (!loading && !data) return <p className="py-3">No item to show</p>;
  return (
    <div className="overflow-x-auto">
      <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard pt-3 rounded-bl-lg rounded-br-lg">
        <table className="min-w-full">
          <TableHeader data={headers} />
          <tbody className="bg-white">
            {
              data.map((data, i) => <TableRow key={i} data={data} rowActions={rowActions} highlight={highlight} />)
            }

            {
              data.length <= 0 && <p className="py-3 text-gray-400">No results</p>
            }
          </tbody>
        </table>

      </div>
    </div>
  )
}

export default Table;
