import React from 'react'

const Loading = ({ color = 'blue', className }) => {

  const generateTextColor = () => {
    switch (color) {
      case 'blue':
        return 'text-blue-500';
      case 'red':
        return 'text-red-500';
      case 'green':
        return 'text-green-500';
      case 'yellow':
        return 'text-yellow-500';
      case 'purple':
        return 'text-purple-500';
      case 'indigo':
        return 'text-indigo-500';
      case 'pink':
        return 'text-pink-500';
      case 'white':
        return 'text-white';
      default:
        return 'text-black'
    }
  }

  return (
    <svg className={`animate-spin ${generateTextColor()} ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
};

export default Loading;
