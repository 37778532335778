import React, { useState } from 'react'
// components 
import Table from '../../components/table';
import Button from '../../components/button';
import Modal from '../../components/modal';
import Input from '../../components/input';
import { rtdb } from '../../helpers/firebase';
import { child, push, ref, remove, update } from 'firebase/database';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';

const CancelTable = ({ data, loading, settingsField = 'cancellation_reasons/driver', title = 'Cancellation reasons for Driver'
}) => {
  const [editData, setEditData] = useState({ loading: true });
  const [showModal, setShowModal] = useState(false);

  const tableHeaders = [
    { name: 'Title' },
    { name: 'Description' },
    { name: 'Actions' },
  ];

  // table data
  const tableData = data ? Object.entries(data).map(addOn => {
    const { title, description } = addOn[1];
    return {
      id: addOn[0],
      title,
      description
    }
  }) : [];


  const handleChange = (e) => {
    const value = e.target.value;
    const field = e.target.id;
    const prevState = { ...editData };
    prevState[field] = value;
    setEditData({ ...prevState });
  };

  const handleModalOpen = async (id) => {
    setShowModal(true);
    if (id) {
      setEditData({
        ...data[id],
        id,
        loading: false
      });
    }
    else {
      setEditData({
        title: '',
        description: '',
        loading: false,
        new: true
      });
    }
  }


  const handleCloseModal = () => {
    setShowModal(false);
    setEditData({ loading: true })
  }


  const handleUpdate = (id, editData, callback) => {
    const _data = editData;
    delete _data.loading;

    const fieldRef = ref(rtdb, `settings/${settingsField}/${id}`)

    update(fieldRef, { ..._data }).then(err => {
      if (err) {
        toast.error('Operation failed');
      }
      else {
        toast.success('Item successfully updated!');
        callback();
      }
    });
  }

  const handleCreate = (editData, callback) => {
    const _data = editData;
    delete _data.loading;
    delete _data.new;

    const newKey = push(child(ref(rtdb), `settings/${settingsField}`)).key;

    const updates = {};
    updates[`settings/${settingsField}/` + newKey] = _data;



    update(ref(rtdb), updates).then(() => {
      toast.success('Successfully added!');
      callback();
    }).catch(err => console.log(err));
  }

  const handleDelete = (id) => {

    const fieldRef = ref(rtdb, `settings/${settingsField}/${id}`)
    remove(fieldRef).then(err => {
      if (err) {
        toast.error('Operation Failed');
      }
      else {
        toast.success('Item successfully deleted!');
      }
    });
  };

  const actions = [
    {
      name: 'Edit',
      action: handleModalOpen
    },
    {
      name: 'Delete',
      action: id => handleDelete(id, 'add_ons'),
      btnType: 'btn-error'
    }
  ];

  return (
    <>
      {/* ADD ONS */}
      <h1 className="text-xl font-bold mb-2 capitalize">
        {title}
      </h1>
      <Table headers={tableHeaders} data={tableData} rowActions={actions} loading={loading} />
      <div className="mt-6 mb-12">

        <button className='btn btn-primary gap-2' onClick={() => handleModalOpen(null)}>
          Add new
          <FaPlus />
        </button>
      </div>

      {/* ADD MODAL */}
      <Modal handleClose={handleCloseModal} showModal={showModal}>
        <h1 className="text-xl font-bold mb-2">
          {editData?.new ? 'Add new' : 'Edit'}
        </h1>
        <div className="-mx-3 md:flex mb-6">
          <Input
            className="md:w-full px-3"
            name="Title"
            id="title"
            value={editData?.title}
            placeholder={`Title of ${title}`}
            onChange={handleChange}
          />
        </div>
        <div className="-mx-3 md:flex mb-6">
          <Input
            className="md:w-full px-3"
            name="Description"
            id="description"
            value={editData?.description}
            placeholder={`${title} description (optional)`}
            onChange={handleChange}
            helperText="Make it short. One liner would be good. This will show in-app"
          />
        </div>
        <div className="flex justify-end">
          {
            editData?.new ?
              <button className='btn btn-primary btn-wide' onClick={() => handleCreate(editData, handleCloseModal)}>Add</button>
              : <button className='btn btn-primary btn-wide' onClick={() => handleUpdate(editData?.id, editData, handleCloseModal)}>Save</button>
          }
        </div>
      </Modal>
    </>
  )
}

export default CancelTable;