import React, { useEffect, useState } from 'react';

// firebase
// import firebase from "gatsby-plugin-firebase";
// import { useListVals } from 'react-firebase-hooks/database';

// maps
import GoogleMapReact from 'google-map-react';

// components
import Layout from '../components/layout';
import Loading from '../components/loading';
import { getRegionForCoordinates } from '../helpers/helpers';
import { FaMotorcycle } from 'react-icons/fa';
import DriverPin from '../components/driverPin';


const DriverTracker = (params) => {
  // const rtdb = firebase.database();
  // const [drivers, loading, error] = useListVals(
  //   rtdb.ref('drivers')
  // );

  // console.log('drivers :>> ', drivers);

  // if (loading) return <Loading />;




  // const centerCoords = getRegionForCoordinates( drivers.map(driver => {
  //     return {
  //       latitude: driver.coords.latitude,
  //       longitude: driver.coords.longitude,
  //     }
  //   }));



  // console.log('centerCoords :>> ', centerCoords);


  return (
    <>
      <div className="h-screen w-full">\
        TODO
        {/* <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyARjle7Kfqkgb_fEQ2ooHFcjDXVH6cspHY' }}
            // defaultCenter={centerCoords}
            defaultZoom={11}
            center={centerCoords}
          >

            {
              drivers.map((driver, i) => {

                return (
                  <DriverPin key={i} driver={driver}  lat={driver.coords.latitude}
                  lng={driver.coords.longitude}/>
                )
              })
            }
          
          </GoogleMapReact> */}
      </div>
    </>
  )
}

export default DriverTracker;