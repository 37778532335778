import React, { useState } from 'react'
// components 
import Table from '../../components/table';
import Button from '../../components/button';
import Modal from '../../components/modal';
import Input from '../../components/input';
import { rtdb } from '../../helpers/firebase';
import { child, push, ref, remove, update } from 'firebase/database';
import { FaPlus } from 'react-icons/fa';


const AddOnsTable = ({ data, loading, handleUpdate,
  handleCreate,
  handleDelete }) => {
  const [editData, setEditData] = useState({ loading: true });
  const [showModal, setShowModal] = useState(false);



  const tableHeaders = [
    { name: 'Name' },
    { name: 'Description' },
    { name: 'Price' },
    { name: 'Actions' },
  ];

  // table data
  const tableData = data?.add_ons && Object.entries(data.add_ons).map(addOn => {
    return {
      id: addOn[0],
      name: addOn[1].name,
      description: addOn[1].description,
      price: addOn[1].price,
    };
  });


  const handleAddOnChange = (e) => {
    const value = e.target.value;
    const field = e.target.id;
    const prevState = { ...editData };
    prevState[field] = value;
    setEditData({ ...prevState });
  };





  const handleModalOpen = async (id) => {
    setShowModal(true);
    if (id) {
      setEditData({
        ...data.add_ons[id],
        id,
        loading: false
      });
    }
    else {
      setEditData({
        name: '',
        description: '',
        price: '',
        loading: false,
        new: true
      });
    }
  }


  const handleCloseModal = () => {
    setShowModal(false);
    setEditData({ loading: true })
  }

  // const _delete = (id) => {
  //   handleDelete(id, 'add_ons');
  // }

  const actions = [
    {
      name: 'Edit',
      action: handleModalOpen
    },
    {
      name: 'Delete',
      action: id => handleDelete(id, 'add_ons'),
      btnType: 'btn-error'
    }
  ];

  return (
    <>
      {/* ADD ONS */}
      <h1 className="text-xl font-bold mb-2">
        Addons
      </h1>
      <Table headers={tableHeaders} data={tableData} rowActions={actions} loading={loading} />
      <div className="mt-6 mb-12">
        <button className='btn btn-primary gap-2' onClick={() => handleModalOpen(null)}>Add a new add-on<FaPlus /></button>
      </div>

      {/* ADD MODAL */}
      <Modal handleClose={handleCloseModal} showModal={showModal}>
        <h1 className="text-xl font-bold mb-2">
          {editData?.new ? 'Add new' : 'Edit'}
        </h1>
        <div className="-mx-3 md:flex mb-6">
          <Input
            className="md:w-2/3 px-3"
            name="Add-on name"
            id="name"
            value={editData?.name}
            placeholder="Add-on Name"
            onChange={handleAddOnChange}
          />
          <Input
            className="md:w-1/3 px-3"
            name="Add-on price"
            id="price"
            value={editData?.price}
            placeholder="Price"
            onChange={handleAddOnChange}
            type="number"
          />
        </div>
        <div className="-mx-3 md:flex mb-6">
          <Input
            className="md:w-full px-3"
            name="Add-on description"
            id="description"
            value={editData?.description}
            placeholder="Add-on Description"
            onChange={handleAddOnChange}
            helperText="Make it short. One liner would be good. This will show in-app"
          />
        </div>
        <div className="md:flex justify-end">
          {
            editData?.new ? <button className='btn btn-primary' onClick={() => handleCreate('add_ons', editData, handleCloseModal)}>Add</button> : <button className='btn btn-primary' onClick={() => handleUpdate(editData?.id, 'add_ons', editData, handleCloseModal)}>Save</button>
          }
        </div>
      </Modal>
    </>
  )
}

export default AddOnsTable;