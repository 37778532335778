import classNames from 'classnames';
import React from 'react'
import { X } from 'react-feather';
import { FaTimes } from 'react-icons/fa';

const Modal = ({ children, handleClose, showModal, isTestOnly, isTester }) => {
  // if (!showModal) return null;
  return (
    // container
    <div className={
      classNames("fixed flex items-center justify-end top-0 left-0 w-full h-full transition-all bg-black/50 z-20",
        (showModal ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'),
      )
    }>
      <div className={
        classNames(
          "relative  bg-white shadow-2xl w-full md:w-8/12 max-w-[1000px] h-full transition-transform duration-300",
          (showModal ? 'translate-x-0' : 'translate-x-full')
        )
      }>
        {
          isTestOnly && <div className='bg-blue-500 text-white text-center text-sm font-bold py-1'>
            THIS IS A MOCK DATA CREATED BY A TEST USER.
          </div>
        }
        {
          isTester && <div className='bg-blue-500 text-white text-center text-sm font-bold py-1'>
            TESTER ACCOUNT
          </div>
        }
        <button className="absolute top-8 -left-5 p-2 block bg-gray-300 rounded-full shadow-2xl hover:scale-125 transition-all" onClick={handleClose}>
          <X size="25" />
        </button>
        <div className="overflow-y-scroll p-10 h-full">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal;

