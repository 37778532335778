import React from "react";
import { navigate } from "gatsby";
import { isLoggedIn } from "../services/auth";
import Layout from "./layout";
import { window } from 'browser-monads';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/app/login`) {
    window.location = '/app';
    return null
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  )
}

export default PrivateRoute