import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { handleLogin, isLoggedIn } from "../services/auth"
import Button from "../components/button";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../helpers/firebase";
import { query } from "firebase/database";

const Login = () => {
  const [state, setState] = useState({
    username: ``,
    password: ``,
  });

  const handleUpdate = event => {
    const prevState = { ...state };
    setState({
      ...prevState,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault();
    handleLogin(state)
  }

  return (
    <section className=" bg-yellow-400 h-screen w-screen">
      <div className="flex items-center justify-center w-full h-full">

        <div className="text-center p-8 bg-white rounded-2xl shadow-2xl w-[300px]">
          <h1 className="font-bold text-2xl block">Admin Login</h1>
          <hr className="my-4" />
          {/* <br/> */}
          <form
            method="post"
            onSubmit={handleSubmit}
          >
            <label className="font-admin font-bold mb-2">
              Username <br />
              <input type="text" name="username"
                placeholder="email"
                className="input input-secondary w-full"
                onChange={handleUpdate} />
            </label>
            <br />
            <br />
            <label className="font-admin font-bold mb-2">
              Password <br />
              <input
                className="input input-secondary w-full"
                type="password"
                name="password"
                placeholder="password"
                onChange={handleUpdate}
              />
            </label>
            <br />
            <br />
            <button className="btn btn-primary w-full" type="submit">Log in </button>
            {/* <input type="submit" value="Log In"
              className="rounded bg-blue-300 w-200px py-3"
            /> */}
          </form>
        </div>
      </div>

    </section>
  )
}

export default Login
