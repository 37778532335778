import React, { useState } from 'react'
// components 
import Table from '../../components/table';
import Button from '../../components/button';
import Modal from '../../components/modal';
import Input from '../../components/input';
import { child, push, ref, update } from 'firebase/database';
import { rtdb } from '../../helpers/firebase';
import { FaPlus } from 'react-icons/fa';


const VehicleTable = ({ data, loading }) => {
  const [editData, setEditData] = useState({ loading: true });
  const [showModal, setShowModal] = useState(false);

  const tableHeaders = [
    { name: 'Name' },
    { name: 'Max Weight' },
    { name: 'Base Price' },
    { name: 'Actions' },
  ];

  // table data
  const tableData = data?.vehicle_types && Object.entries(data.vehicle_types).map(type => {
    return {
      id: type[0],
      name: type[1].name,
      // description: type[1].description,
      maxWeight: type[1].maxWeight,
      basePrice: type[1].basePrice,
    };
  });


  const handleAddOnChange = (e) => {
    const value = e.target.value;
    const field = e.target.id;
    const prevState = { ...editData };
    prevState[field] = value;
    setEditData({ ...prevState });
  };


  const handleAddOnUpdate = (id) => {
    const message = 'Are you sure you want to update this Vehicle?';
    const _data = editData;
    delete _data.loading;
    if (window.confirm(message)) {

      const vehicleRef = ref(rtdb, `settings/vehicle_types/${id}`);

      update(vehicleRef, { ..._data }).then(err => {
        if (err) {
          alert('Operation failed');
        }
        else {
          alert('Vehicle successfully updated!');
          handleCloseModal();
        }
      }).catch(err => {
        alert('Operation failed');
      });


    }
  }

  const handleAddOnCreate = () => {
    const _data = editData;
    delete _data.loading;
    delete _data.new;

    const newVehicleKey = push(child(ref(rtdb), 'settings/vehicle_types')).key;

    const updates = {};
    updates['settings/vehicle_types/' + newVehicleKey] = _data;

    update(ref(rtdb), updates).then(() => {
      alert('New Card added!');
      handleCloseModal();
    });
  }

  const handleModalOpen = async (id) => {
    setShowModal(true);
    if (id) {
      setEditData({
        ...data.vehicle_types[id],
        id,
        loading: false
      });
    }
    else {
      setEditData({
        name: '',
        description: '',
        price: '',
        loading: false,
        new: true
      });
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setEditData({ loading: true })
  }

  const handleAddOnDelete = (id) => {
    const message = 'Are you sure you want to delete this Vehicle?';

    if (window.confirm(message)) {
      rtdb.ref(`settings/vehicle_types/${id}`).remove().then(err => {
        if (err) {
          alert('Operation Failed');
        }
        else {
          alert('Vehicle successfully deleted!');
        }
      });
    }

  }

  const actions = [
    {
      name: 'Edit',
      action: handleModalOpen
    },
    {
      name: 'Delete',
      action: handleAddOnDelete,
      btnType: 'btn-error'
    }
  ];

  return (
    <>
      {/* ADD ONS */}
      <h1 className="text-xl font-bold mb-2">
        Vehicle Types (Buggy)
      </h1>
      <Table headers={tableHeaders} data={tableData} rowActions={actions} loading={loading} />
      <div className="mt-6 mb-12">

        <button className='btn btn-primary gap-2' onClick={() => handleModalOpen(null)}>Add a new Vehicle<FaPlus /></button>
      </div>

      {/* ADD MODAL */}
      <Modal handleClose={handleCloseModal} showModal={showModal}>
        <h1 className="text-xl font-bold mb-2">
          {editData?.new ? 'Add new' : 'Edit'}
        </h1>
        <div className="-mx-3 md:flex mb-6">
          <Input
            className="md:w-full px-3"
            name="Unique identifier"
            id="identifier"
            value={editData?.identifier}
            placeholder="Identifier"
            onChange={handleAddOnChange}
            helperText="This unique identifier will be the one saved in database. This is important to know where to properly assign a booking (ex: bicycle-escooters)"
          />
        </div>
        <div className="-mx-3 md:flex mb-6">
          <Input
            className="md:w-2/3 px-3"
            name="Vehicle name"
            id="name"
            value={editData?.name}
            placeholder="Vehicle Name"
            onChange={handleAddOnChange}
          />
          <Input
            className="md:w-1/3 px-3"
            name="Base price"
            id="basePrice"
            value={editData?.basePrice}
            placeholder="Price"
            onChange={handleAddOnChange}
            type="number"
          />
          <Input
            className="md:w-1/3 px-3"
            id="maxWeight"
            value={editData?.maxWeight}
            placeholder="Kilo"
            onChange={handleAddOnChange}
            type="number"
          />
        </div>
        <div className="-mx-3 md:flex mb-6">
          <Input
            className="md:w-1/3 px-3"
            name="Per Km Value"
            id="perKmValue"
            value={editData?.perKmValue}
            placeholder="Price per KM"
            onChange={handleAddOnChange}
          />
          <Input
            className="md:w-1/3 px-3"
            name="Icon"
            id="icon"
            value={editData?.icon}
            placeholder="Material Community Icon"
            onChange={handleAddOnChange}
            helperText="Material Community Icon"
          />

        </div>
        <div className="-mx-3 md:flex mb-6">
          <Input
            className="md:w-full px-3"
            name="Vehicle description"
            id="description"
            value={editData?.description}
            placeholder="Vehicle Description"
            onChange={handleAddOnChange}
            helperText="This only shows to driver app"
          />
        </div>
        <div className="md:flex justify-end">
          {
            editData?.new ? <button className='btn btn-primary' onClick={handleAddOnCreate}>Add</button> : <button className='btn btn-primary' onClick={() => handleAddOnUpdate(editData?.id)}>Save</button>
          }
        </div>
      </Modal>
    </>
  )
}

export default VehicleTable;