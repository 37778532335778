import React from 'react'
import Label from './label';


const Input = props => {

  const {
    name,
    className = 'md:w-full px-3',
    id = 'input-field',
    placeholder = 'n/a',
    label = name || id.split('_').join(' '),
    value = '',
    helperText = '',
    disabled = false,
    onChange,
    type = 'text'
  } = props;
  return (
    <div className={className}>
      <Label for={id}>
        {label}
      </Label>
      <input
        className="input input-bordered input-primary w-full border-black"
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        min={0}
      // {...props}
      />
      {
        helperText && <p className="text-red text-xs italic">{helperText}</p>
      }

    </div>
  )
}

export default Input;
