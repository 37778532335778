import React from 'react'

const sampleData = [
  {
    name: 'Test 1'
  },
  {
    name: 'Test 2'
  },
  {
    name: 'Test 3'
  },
];

const TableHeader = ({ data }) => {
  const headers = data || sampleData;
  return (
    <thead>
      <tr>
        {
          headers.map((data, i) => (
            <th key={i} className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-black">{data.name}</th>
          ))
        }
      </tr>
    </thead>
  )
}

export default TableHeader;
