import React, { } from 'react'
// firebase
import AddOnsTable from './settings/addOnsTable';
import VehicleTable from './settings/vehicleTable';
import CancelTable from './settings/cancelTable';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaExternalLinkAlt } from 'react-icons/fa';

const Feedback = () => {



  return (
    <div className='flex flex-col font-admin h-[calc(100vh-40px)]'>
      <div className="flex items-center justify-between mb-4">

        <h1 className="text-2xl font-bold mb-2">
          Feedback
        </h1>
        <a className='btn btn-primary gap-2' target="_blank" href='https://docs.google.com/forms/d/e/1FAIpQLSfQmDG2plPE87F94QFqILPh6EBz7l2OVUBmq2aaMuLBPFjMcA/viewform' rel="noreferrer">View in Google Forms <FaExternalLinkAlt /></a>
      </div>
      <div className='flex-1 border-2 border-green-800'>

        <iframe className='w-full h-full' src="https://docs.google.com/spreadsheets/d/e/2PACX-1vR1RSxwoBoY1nZxLIu-y3pOwc7CZkS5ZvdJ51FMOFsBQ0-fE5xOU8Te7uW7xdFCg5Z3ct0_KtSfUfq1/pubhtml?gid=1835672972&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
      </div>
    </div>
  )
}

export default Feedback;





