import React, { useRef } from 'react'
import { Trash } from 'react-feather';
import { FaSearch, FaTimes } from 'react-icons/fa';
import Button from './button';
// import Loading from './loading';
// import { FaTimes } from 'react-icons/fa';
import Loading from './loading';


const Toolbar = props => {
  const inputRef = useRef();
  const {
    toolbarActions = [],
    searchHandler = () => console.log('action'),
    searchPlaceholder = 'Type a keyword and press enter...',
    loading = false
  } = props;

  const handleSubmit = e => {
    e.preventDefault();
    if (e.key === "Enter" || e.target.value == '') {
      searchHandler(e.target.value);
    }
  }

  const handleClear = () => {
    inputRef.current.value = '';
    setTimeout(() => {
      searchHandler('');

    }, 10);
  }

  return (
    <div className="flex justify-between mb-6">
      <div className="inline-flex border-2 border-black/50 rounded w-7/12 px-2 lg:px-6 h-12 bg-transparent">
        <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
          <div className="flex">
            <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-gray-dark text-sm">
              <FaSearch />
            </span>
          </div>
          <input
            ref={inputRef}
            type="text"
            className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px flex-1 border border-none border-l-0 rounded rounded-l-none px-3 relative focus:outline-none text-xxs lg:text-xs lg:text-base text-gray-500"
            placeholder={searchPlaceholder}
            onKeyUp={handleSubmit}
          />
          {
            loading && <Loading color="blue" className="self-center h-5 w-5" />
          }

          {
            !loading && inputRef.current?.value !== '' && (
              <button className="h-8 w-8 self-center flex items-center justify-center bg-gray-300 rounded-full" onClick={handleClear}>
                {/* <FaTimes/> */}
                <FaTimes />
              </button>
            )
          }


        </div>
      </div>
      <div>

        {
          toolbarActions.map(({
            actionTitle,
            action,
            clear = false,
            variant,
            color
          }, i) => <Button key={i} onClick={() => {
            clear && handleClear();
            action();
          }} variant={variant} color={color}>{actionTitle}</Button>
          )
        }
      </div>

    </div>

  )
}

export default Toolbar;
